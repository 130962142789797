import { useState } from "react";
import { Row, Col, Drawer, Image } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
// import { SvgIcon } from "../../common/SvgIcon";
// import '../../common/tames_assist_logo.png'
import "./tames_assist_logo.png";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  OutsideLinkSmall,
  PageLink,
} from "./styles";
const goThere = () => {
  window.location.href = "https://tamesassist.com/";
};
const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);
  const [path, setPath] = useState(window.location.pathname);

  const MenuItem = () => {
    const scrollTo = (id: string) => {

      if (window.location.pathname === "/") {
        const element = document.getElementById(id) as HTMLDivElement;
        element?.scrollIntoView({
          behavior: "smooth",
        });
        setVisibility(false);
      }

    };

    return (
      <>
        <PageLink
          to="/about"
          onClick={() => {
            setPath("/about");
          }}
        >
          {t("About")}
        </PageLink>
        {path === "/" ? (
          <>
            {" "}
            <PageLink
              to="/"
              onClick={() => {
                window.scrollTo(0, document.body.scrollHeight);
                setPath("/");
              }}
            >
              <Span>{t("Contact Us")}</Span>
            </PageLink>
          </>
        ) : (
          <>
            {" "}
            <PageLink
              to="/"
              onClick={() => {
                setPath("/");
              }}
            >
              <Span>{t("Home")}</Span>
            </PageLink>
          </>
        )}

        <OutsideLinkSmall style={{ width: "180px" }}>
          <Span>
            <Button onClick={goThere}>Login</Button>
          </Span>
        </OutsideLinkSmall>

      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <Image width={200} src={require("./tames_assist_logo.png")} />
          </LogoContainer>

          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={() => setVisibility(true)}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={() => setVisibility(false)}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={() => setVisibility(false)}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
