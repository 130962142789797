import { Row, Col } from "antd";
import { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };


  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  const isMobile = width <= 768;

  return (
    <>
      <FooterSection>
        <Container>
          {isMobile ? <> <div style={{ transform: "scale(1)" }}>
            © {new Date().getFullYear()} TAMES/Health Resource Service
            Management, Inc.
          </div></> : <><Row>
            <Col span={8}>{''}

            </Col>
            <div style={{ transform: "scale(1.2)" }}>
              © {new Date().getFullYear()} TAMES/Health Resource Service
              Management, Inc.
            </div>
          </Row></>}

        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>{ }</Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
